import React from "react";
import { useEffect, useState } from "react";
import instance from "../utils/Api";
import {
  Stepper,
  Step,
  StepLabel,
  DialogContent,
  TextField,
  MenuItem,
  Select,
  DialogContentText,
  DialogActions,
  useMediaQuery,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { Button, Container, Typography, FormLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import cartimage from "../images/cart/cart-image.svg";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BasicAccordion3 from "../components/common/Accordion3";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import CustomizedDialogs3 from "./CustomizedDialogs3";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useCart } from "../pages/CartContext";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import leaf from "../images/confirmation/flower.svg";
import ganesh from "../images/confirmation/lord-ganesh.svg";
import api from "../config/backend";
import { IoIosClose, IoMdArrowRoundBack } from "react-icons/io";
import { IoCloseCircle } from "react-icons/io5";
import axios from "axios";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import PencilIcon from "../images/champaign/pencil_icon.svg";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import countryData from "../utils/countrycode.json";

function NonLinearHorizontalStepper2({ setTriggerRefresh, sevaPriceID }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const donation = queryParams.get("donation") === "true";
  const sevas = queryParams.get("sevas") === "true";
  const events = queryParams.get("events") === "true";
  const individual = queryParams.get("individual") === "true";

  const [open, setOpen] = useState(false);
  const { productId, customPrice } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedSellingPrice, setSelectedSellingPrice] = useState(null);
  const [error, setError] = useState(null);
  const [productDetails, setProductDetails] = useState([]);
  const [stupidity, setStupidity] = useState(undefined);
  const [familyData, setFamilyData] = useState([]);
  const [selectedFamily, setSelectedFamily] = useState([]);
  const [isSelectedFamily, setIsSelectedFamily] = useState(undefined);
  const token = localStorage.getItem("token");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone_no, setPhoneNo] = useState("");
  const [whatsup_no, setWhatsupNo] = useState("");
  const [country_id, setCountryId] = useState(1);
  const [state_id, setStateId] = useState("");
  const [city_id, setCityId] = useState("");
  const [address_1, setAddress1] = useState("");
  const [address_2, setAddress2] = useState("");
  const [pincode, setPincode] = useState("");
  const [address_name, setAddressName] = useState("");
  const [cityData, setCityData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [optForPrasadam, setOptForPrasadam] = useState(true);
  const [familycheck, setFamilyCheck] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [extrachargesLabel, setExtrachargesLabel] = useState("");
  const [extrachargesPercentage, setExtrachargesPercentage] = useState();
  const [addressNameOptions, setAddressNameOptions] = useState([
    { id: "home", name: "Home" },
    { id: "office", name: "Office" },
    { id: "other", name: "Other" },
  ]);
  const [otherAddressName, setOtherAddressName] = useState("");
  const [cartData, setCartData] = useState([]);
  const [price, setPrice] = useState([]);
  const [addresses, setAddresses] = useState([]);
  // const [triggerRefresh, setTriggerRefresh] = React.useState(0)
  const [selectedState, setSelectedState] = useState("");
  const [citiesInSelectedState, setCitiesInSelectedState] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [isIndia, setIsIndia] = useState(true);
  const [popUp, setPopUp] = useState(false);
  // Store the selected seva
  const [selectedProduct, setSelectedProduct] = useState(undefined);

  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width: 767px)");
  const [isMulKartaSelected, setIsMulKartaSelected] = useState(false);
  const [isMulAncestorSelected, setIsMulAncestorSelected] = useState(false);
  const [addressRequired, setAddressRequired] = useState(true);

  useEffect(() => {
    if (individual) {
      setAddressRequired(true);
    }
  }, [individual]);
  // console.log(selectedProduct, 'selectedProduct')

  const handleAddressNameChange = (e) => {
    const selectedValue = e.target.value;

    if (selectedValue === "other") {
      // If "Other" is chosen, reset otherAddressName
      setOtherAddressName("");
    }

    setAddressName(selectedValue);
  };
  const [fieldErrors, setFieldErrors] = useState({
    fname: false,

    email: false,
    phone_no: false,
    whatsup_no: false,
    state_id: false,
    city_id: false,
    address_1: false,
    pincode: false,
    address_name: false,
  });
  const validateForm = () => {
    const errors = {};
    const nameRegex = /^[A-Za-z]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[6-9]\d{9}$/;

    if (!fname || !nameRegex.test(fname)) errors.fname = true;
    if (!email || !emailRegex.test(email)) errors.email = true;
    if (!phone_no || !phoneRegex.test(phone_no)) errors.phone_no = true;
    if (!whatsup_no || !phoneRegex.test(whatsup_no)) errors.whatsup_no = true;
    if (!selectedState) errors.selectedState = true;
    if (!selectedCity) errors.selectedCity = true;
    if (!address_1) errors.address_1 = true;
    if (!pincode) errors.pincode = true;
    if (address_name === "other" && !otherAddressName)
      errors.otherAddressName = true;

    setFieldErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      return false;
    }

    return true;
  };

  const [tipPercentage, setTipPercentage] = useState("14");
  const [tipAmount, setTipAmount] = useState(null);
  const [isCustomTip, setIsCustomTip] = useState(false);

  const newprice = customPrice ? customPrice : selectedProduct?.selling_price;

  useEffect(() => {
    if (newprice) {
      if (events || donation) {
        const calculatedTip = (newprice * tipPercentage) / 100;
        setTipAmount(calculatedTip?.toFixed(2));
      }
    }
  }, [newprice]);

  const handleTipChange = (event) => {
    const selectedValue = event.target.value;

    if (selectedValue === "other") {
      setIsCustomTip(true);
      setTipPercentage("");
      setTipAmount(null);
    } else {
      setIsCustomTip(false);
      const calculatedTip = (newprice * selectedValue) / 100;
      setTipPercentage(selectedValue);
      setTipAmount(calculatedTip?.toFixed(2));
    }
  };

  const handleCustomTipChange = (event) => {
    const customTip = parseFloat(event.target.value) || 0;
    setTipAmount(customTip);
    setTipPercentage("other");
  };

  const handleRevertToDropdown = () => {
    setIsCustomTip(false);
    setTipAmount(null);
    setTipPercentage("");
  };

  const handleSaveAddress = async () => {
    window.fbq("track", "InputData");
    // if (!validateForm()) {
    //   return;
    // }

    try {
      const postData = {
        // fname,
        // lname,
        // email,
        // phone_no,
        // whatsup_no,
        // country_id: 1,
        state_id: selectedState,
        city_id: selectedCity,
        country_id: "1",
        address_1,
        address_2,
        pincode,
        // address_name:
        //   address_name === "other" ? otherAddressName : address_name,
      };

      if (isEditMode && currentAddress) {
        const updatedFields = {};
        Object.keys(postData).forEach((key) => {
          if (postData[key] !== currentAddress[key]) {
            updatedFields[key] = postData[key];
          }
        });

        Object.keys(updatedFields).forEach((key) => {
          if (updatedFields[key] === undefined) {
            delete updatedFields[key];
          }
        });

        console.log("updated fields:", updatedFields);

        if (Object.keys(updatedFields).length === 0) {
          alert("Please atleast update 1 field");
          return;
        }

        const response = await axios.put(
          `${api}/api/address/${currentAddress?.id}`,
          updatedFields,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        setAddresses((prevAddresses) =>
          prevAddresses.map((addr) =>
            addr?.id === currentAddress?.id ? response?.data?.data : addr
          )
        );
      } else {
        const response = await axios.post(`${api}/api/address`, postData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        setAddresses((prevAddresses) => [...prevAddresses, response.data.data]);
        if (events || individual) {
          setSelectedAddressId();
          handleCheckout(response.data.data.id);
        }
      }

      handleCloseAddAddressModal();
      // setFname("");
      // setLname("");
      // setEmail("");
      // setPhoneNo("");
      // setWhatsupNo("");
      setSelectedState("");
      setSelectedCity("");
      setCountryId("");
      setStateId("");
      setCityId("");
      setAddress1("");
      setAddress2("");
      setPincode("");
      setAddressName("");

      setTriggerRefresh((prev) => prev + 1);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        alert(
          `An error occurred while saving the address: ${error.response.data.message}`
        );
      }
    }
  };
  const [isAddAddressModalOpen, setAddAddressModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(null);

  // console.log('selectedCity----', selectedCity)
  // console.log('address_name----', address_name)

  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    // Simple email regex validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (!validateEmail(newEmail)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  useEffect(() => {
    console.log(sevas, events, "lkjedkjwebdkjwedc");
  }, [sevas, events]);

  const handleOpenAddAddressModal = () => {
    setIsEditMode(false);
    setAddAddressModalOpen(true);
  };

  const handleCloseAddAddressModal = () => {
    setAddAddressModalOpen(false);
    setIsEditMode(false);
    setCurrentAddress(null);
    setFname("");
    setLname("");
    setEmail("");
    setPhoneNo("");
    setWhatsupNo("");
    setSelectedState("");
    setSelectedCity("");
    setCountryId("");
    setStateId("");
    setCityId("");
    setAddress1("");
    setAddress2("");
    setPincode("");
    setAddressName("");
    setFieldErrors({
      fname: false,

      email: false,
      phone_no: false,
      whatsup_no: false,
      state_id: false,
      city_id: false,
      address_1: false,

      pincode: false,
      address_name: false,
    });
  };

  const handleOpenEditAddressModal = (address) => {
    setIsEditMode(true);
    setCurrentAddress(address);
    setAddAddressModalOpen(true);

    setFname(address?.fname);
    setLname(address?.lname);
    setEmail(address?.email);
    setPhoneNo(address?.phone_no);
    setWhatsupNo(address?.whatsup_no);
    setSelectedState(address.state_id);
    setSelectedCity(address.city_id);
    setCountryId(address?.country_id);
    setStateId(address?.state_id);
    setCityId(address?.city_id);
    setAddress1(address?.address_1);
    setAddress2(address?.address_2);
    setPincode(address?.pincode);
    setAddressName(
      address?.address_name !== "home" && address?.address_name !== "office"
        ? "other"
        : address?.address_name
    );
    // if(otherAddressName === "other"){
    //   setOtherAddressName(address?.address_name);
    // }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  const steps = ["Information", "Complete "];

  const handleStepClick = (step) => () => {
    if (familyData && familyData.length) {
      setActiveStep(step);
    }
  };

  const hasKarta = familyData.some(
    (familyMember) => familyMember.family_type === "kartha"
  );
  const hasAncestor = familyData.some(
    (familyMember) => familyMember.family_type === "ancestors"
  );

  const isKartaAndAncestor = hasKarta && hasAncestor;

  console.warn("familydata:", familyData);

  // let selectedPriceItem
  const { setOrderStatus } = useCart();

  const calculateTotal = () => {
    let total = 0;
    if (customPrice) {
      total = extrachargesLabel
        ? customPrice + customPrice * (extrachargesPercentage / 100)
        : customPrice;
    } else if (selectedProduct && !customPrice && !addressRequired) {
      total = extrachargesLabel
        ? selectedProduct.selling_price +
          selectedProduct.selling_price * (extrachargesPercentage / 100)
        : selectedProduct.selling_price;
    } else if (selectedProduct && !customPrice && addressRequired) {
      total = extrachargesLabel
        ? selectedProduct.selling_price +
          selectedProduct.selling_price * (extrachargesPercentage / 100) +
          70
        : selectedProduct.selling_price + 70;
    }
    // if (selectedProduct) {
    //   total = extrachargesLabel
    //     ? selectedProduct.selling_price +
    //       selectedProduct.selling_price * (extrachargesPercentage / 100)
    //     : selectedProduct.selling_price;
    // }
    if (tipAmount) {
      total = Number(total) + Number(tipAmount);
    }

    return Math.ceil(total);
  };

  const fetchAddresses = async () => {
    try {
      const response = await axios.get(`${api}/api/address`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          paginate: "0",
        },
      });

      setAddresses(response.data.data.data);
      setError(null);
    } catch (err) {
      setError(err);
    }
  };

  const fetchSevasData = async () => {
    try {
      const response = await axios.get(`${api}/api/sevas/${productId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          paginate: "0",
        },
      });

      console.log(
        "response inside horizontal Liner Stepper 2:",
        response?.data
      );

      const idontcare = response.data.data;
      const sevaPrices = response.data.data.seva_prices;
      console.log(response.data);
      setIsIndia(response.data.is_india === 1);
      setProductDetails(response.data.data);
      setExtrachargesLabel(response.data.data.extracharges_label);
      setExtrachargesPercentage(response.data.data.extracharges_percentage);

      const thisproduct = sevaPrices.find((item) => item.id == sevaPriceID);
      if (customPrice) {
        setSelectedProduct(response.data.data);
      } else {
        setSelectedProduct(thisproduct);
      }

      let selectedKeys = [];
      const selectedFamilyIds = selectedFamily.map((member) => member.id);
      if (!customPrice) {
        if (Array.isArray(isSelectedFamily)) {
          selectedKeys = isSelectedFamily
            .filter((obj) => {
              if (Object.values(obj)[0]) {
                return true;
              }
              return false;
            })
            .map((obj) => Object.keys(obj)[0]);
        }
      }

      const nothing = [
        {
          seva_id: idontcare.id,
          seva_price_id: !customPrice ? thisproduct.id : 123,
          is_prasadam_available: !customPrice
            ? idontcare?.seva_prices[0]?.is_prasadam_available
            : false,
          user_family_detail_id: !customPrice ? familycheck : [],
        },
      ];
      // console.log(9)
      // console.log(nothing)
      setStupidity(nothing);

      if (sevaPrices && sevaPrices.length > 0 && !customPrice) {
        const firstSellingPrice = sevaPrices[0].selling_price;
        setPrice(sevaPrices);
        setSelectedSellingPrice(firstSellingPrice);
        setTriggerRefresh((prev) => prev + 1);
      } else {
        setPrice(customPrice);
        setSelectedSellingPrice(customPrice);
        setTriggerRefresh((prev) => prev + 1);
      }
    } catch (err) {
      setError(err);
    }
  };

  const getStupidity = async () => {
    const response = await axios.get(`${api}/api/sevas/${productId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        paginate: "0",
      },
    });

    const idontcare = response.data.data;
    let selectedKeys = [];
    const selectedFamilyIds = selectedFamily.map((member) => member.id);
    // console.log('select', isSelectedFamily)
    if (Array.isArray(isSelectedFamily)) {
      selectedKeys = isSelectedFamily
        .filter((obj) => {
          if (Object.values(obj)[0]) {
            return true;
          }
          return false;
        })
        .map((obj) => Object.keys(obj)[0]);
    }

    const nothing = [
      {
        seva_id: idontcare.id,
        seva_price_id: Number(sevaPriceID),
        is_prasadam_available: idontcare?.seva_prices[0]?.is_prasadam_available,
        user_family_detail_id: familycheck,
      },
    ];

    setStupidity(nothing);
  };

  const removeItemFromCart = async () => {
    navigate("/");

    // try {
    //   console.log('selectedProductId', selectedProductId)
    //   console.log('selectedProduct', selectedProduct)
    //     // const response = await axios.delete(`${api}/api/cart/${selectedProductId}`, {
    //     //     headers: {
    //     //       Authorization: `Bearer ${token}`,
    //     //       'Content-Type': 'application/json'
    //     //     }
    //     //   });
    //     // console.log('Cart Item deleted successfully:', response.data);

    //     // setCartData()

    // } catch (error) {
    //     console.error('Error deleting family member:', error);
    // }
  };

  const sendPaymentStatusToServer = async (data, response, status) => {
    try {
      if (status == "Success") {
        navigate(`/payment/${response.razorpay_payment_id}/thank-you`);
        axios.put(
          `${api}/api/bookings/${data.data.id}`,
          {
            payment_status: status,
            transaction_id: response.razorpay_payment_id,
            transaction_response: JSON.stringify(data.razor_pay),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setOrderStatus(true);
        setTimeout(() => {
          setOrderStatus(false);
        }, 3000);
        navigate(`/payment/${response.razorpay_payment_id}/thank-you`);
        window.fbq("track", "Purchase");
      } else if (status == "Cancelled") {
        axios.put(
          `${api}/api/bookings/${data.data.id}`,
          {
            payment_status: status,
            transaction_id: data.data.invoice_id,
            transaction_response: JSON.stringify(data.razor_pay),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
      } else if (response.error.reason == "payment_failed") {
        axios.put(
          `${api}/api/bookings/${data.data.id}`,
          {
            payment_status: status,
            transaction_id: response.error.metadata.payment_id,
            transaction_response: JSON.stringify(data.razor_pay),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
      }
    } catch (error) {
      console.error("Error sending payment status to server", error);
    }
  };
  const [loadingPayment, setLoadingPayment] = useState(false);
  const handleCheckout = async (addrId) => {
    let isallchecked = false;
    if (!customPrice) {
      for (const item of isSelectedFamily) {
        const values = Object.values(item);

        if (values.includes(true)) {
          isallchecked = true;
          break;
        }
      }
    }
    if (!customPrice && !individual) {
      if (!isallchecked && familycheck.length === 0) {
        alert(
          "Please select at least one family member before proceeding to checkout."
        );
        return;
      }
    }
    if (!customPrice) {
      console.log(addressRequired, "lkndlkwndlkwned");
      if (addressRequired) {
        // if (!addrId) {
        //   alert(
        //     "Please select a delivery address before proceeding to checkout."
        //   );
        //   return;
        // }
      }
    }

    if (stupidity || customPrice) {
      // Add prashadam available to stupidity
      const stupiditycopy =
        !customPrice && JSON.parse(JSON.stringify(stupidity));

      // stupiditycopy[0]?.is_prasadam_available = optForPrasadam;
      console.log(selectedProduct, "lkjewbjkfwbefwef");
      try {
        setLoadingPayment(true);
        const { data } = await axios.post(
          `${api}/api/bookings`,
          // `https://a261-150-107-18-216.ngrok-free.app/api/bookings`,
          {
            cart: !customPrice
              ? stupidity
              : [
                  {
                    seva_id: selectedProduct?.id,
                    seva_price_id: null,
                    is_prasadam_available: false,
                    user_family_detail_id: !customPrice ? familycheck : null,
                  },
                ],
            shipping_user_address_id: customPrice
              ? null
              : addrId
                ? addrId
                : selectedAddressId,
            billing_user_address_id: customPrice
              ? null
              : addrId
                ? addrId
                : selectedAddressId,
            original_price: customPrice
              ? customPrice
              : selectedProduct.selling_price,
            final_paid_amount: calculateTotal(),
            is_from_cart: 0,
            phone: phone ? `${countryCode}` + phone : "",
            full_name: phone ? firstName : "",
            email: phone ? email : "",
            donation_id: phone ? selectedProduct?.id : null,
            tip: tipAmount > 0 ? tipAmount : null,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        // console.log('dataaaaaaa', data)
        let UserData = !customPrice && JSON?.parse(data?.data?.billing_address);
        const options = {
          key: data?.razor_pay?.keys.key_id,
          amount: data?.razor_pay?.order.amount,
          currency: data?.razor_pay?.order.currency,
          name: "Devasmriti",
          description: "Total Amount",
          image: "/images/devasmriti-logo.svg",
          order_id: data?.razor_pay?.order?.id,
          handler: function (response) {
            console.log("response=====", response);
            sendPaymentStatusToServer(data, response, "Success");
          },
          modal: {
            ondismiss: function () {
              sendPaymentStatusToServer(data, null, "Cancelled");
            },
          },
          prefill: {
            name: UserData?.fname,
            email: UserData?.email,
            contact: phone
              ? `${countryCode}` + phone
              : localStorage.getItem("number"),
          },
          // notes: {
          // 	address: deliverAddress,
          // },
          retry: {
            enabled: false,
          },
          timeout: 900,
          theme: {
            color: "#3399cc",
          },
        };
        console.log(options, phone, email, UserData, "kjwekfjwefbkwjefgker");
        const razor = new window.Razorpay(options);
        razor.on("payment.failed", function (response) {
          sendPaymentStatusToServer(data, response, "Failed");
        });
        razor.open();
        setLoadingPayment(false);
        // if (data.success) {
        // 	const checkoutlink = data.data.checkout_url

        // 	if (checkoutlink) {
        // 		window.location.href = checkoutlink
        // 	}
        // }
      } catch (error) {
        alert(error);
        console.error("Error during checkout:", error);
        setLoadingPayment(false);
      }
    } else {
      console.error("Cart data (stupidity) is not defined");
    }
  };

  const fetchCityData = async () => {
    try {
      const response = await axios.get(`${api}/api/city`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          paginate: "0",
        },
      });
      if (response.status === 200) {
        const responseData = response.data;
        if (responseData.success === 1) {
          const cityData = responseData.data.data;
          setCityData(cityData);
        } else {
          console.error("API Error:", responseData.message);
        }
      } else {
        console.error("API Error: Unexpected status code", response.status);
      }
    } catch (error) {
      console.error("API Error:", error.message);
    }
  };

  const fetchStateData = async () => {
    try {
      const response = await axios.get(`${api}/api/states`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          paginate: "0",
        },
      });
      if (response.status === 200) {
        const responseData = response.data;
        if (responseData.success === 1) {
          const StateDataa = responseData.data.data;
          setStateData(StateDataa);
          console.log(stateData);
        } else {
          console.error("API Error:", responseData.message);
        }
      } else {
        console.error("API Error: Unexpected status code", response.status);
      }
    } catch (error) {
      console.error("API Error:", error.message);
    }
  };

  const fetchDonationData = () => {
    const url =
      donation && !sevas
        ? `https://api-backend.devasmriti.com/api/donations/${productId}`
        : sevas
          ? `https://api-backend.devasmriti.com/api/sevas/${productId}`
          : `https://api-backend.devasmriti.com/api/events/${productId}`;
    axios.get(url).then(({ data }) => {
      if (data.success) {
        setIsIndia(data.is_india === 1);
        // setProductDetails(data.data);
        setSelectedProduct(data.data);
      }
    });
  };

  useEffect(() => {
    if (!customPrice) {
      fetchCityData();
      fetchStateData();
      fetchAddresses();
      fetchSevasData();
    } else {
      fetchCityData();
      fetchStateData();
      fetchAddresses();
      fetchDonationData();
    }
  }, []);

  useEffect(() => {
    if (!customPrice) {
      getStupidity();
    }
  }, [isSelectedFamily]);

  const handleClosePop = () => {
    setPopUp(false);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(`${api}/api/cart`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           'Content-Type': 'application/json'
  //         }
  //       });
  //       setCartData(response.data.data);
  //       setError(null);
  //     } catch (err) {
  //       setError(err);
  //       console.error(err);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   if (cartData && cartData.data) {

  //     let selectedFamilyIds = [];
  //     let selectedKeys = [];
  //     if (selectedFamily && selectedFamily.length > 0) {
  //       selectedFamilyIds = selectedFamily.map(member => member.id);

  //     }

  //     if (Array.isArray(isSelectedFamily)) {
  //       selectedKeys = isSelectedFamily.map(obj => Object.keys(obj)[0]);

  //     }
  //     const updatedStupidity = cartData.data.map(item => {
  //       return {
  //         "seva_id": item.seva_id,
  //         "seva_price_id": selectedPriceItem.id,
  //         "is_prasadam_available": item.is_prasadam_available,
  //         "user_family_detail_id": selectedKeys,
  //       };
  //     });

  //     setStupidity(updatedStupidity);
  //   }
  // }, [selectedFamily, cartData, sevaPriceID, isSelectedFamily]);

  const conditionalSubmitBtn = () => {
    if (events || individual) {
      if (
        !addressRequired ||
        (address_1 &&
          address_2 &&
          selectedState &&
          selectedCity &&
          pincode &&
          address_name)
      ) {
        return (
          <Button
            className="_0plu"
            variant="outlined"
            onClick={() => {
              if (events && !addressRequired) {
                handleCheckout();
              } else if (events && addressRequired) {
                handleSaveAddress();
              } else if (individual && addressRequired) {
                handleSaveAddress();
              }
            }}
            sx={{ margin: "0 20px!important" }}
          >
            Proceed to Pay
            <ChevronRight sx={{ fontSize: "30px" }} />
          </Button>
        );
      } else {
        return (
          <Tooltip title="Please select a family" placement="bottom">
            <div>
              <Button
                className="disabledButton"
                variant="outlined"
                disabled
                sx={{
                  color: "white!important",
                  backgroundColor: "lightgray",
                  margin: "0 20px!important",
                }}
              >
                Proceed to Pay
                <ChevronRight sx={{ fontSize: "30px" }} />
              </Button>
            </div>
          </Tooltip>
        );
      }
    } else {
      if (customPrice || familycheck.length > 0) {
        return (
          <Button
            className="_0plu"
            variant="outlined"
            onClick={() => {
              if (isKartaAndAncestor) {
                if (!isMulKartaSelected || !isMulAncestorSelected) {
                  setPopUp(true);
                } else {
                  // setActiveStep(1);
                  handleCheckout();
                }
              } else {
                // setActiveStep(1);
                handleCheckout();
              }
              if (events) {
                handleSaveAddress();
              }
            }}
            sx={{ margin: "0 20px!important" }}
          >
            Proceed to Pay
            <ChevronRight sx={{ fontSize: "30px" }} />
          </Button>
        );
      } else {
        return (
          <Tooltip title="Please select a family" placement="bottom">
            <div>
              <Button
                className="disabledButton"
                variant="outlined"
                disabled
                sx={{
                  color: "white!important",
                  backgroundColor: "lightgray",
                  margin: "0 20px!important",
                }}
              >
                Proceed to Pay
                <ChevronRight sx={{ fontSize: "30px" }} />
              </Button>
            </div>
          </Tooltip>
        );
      }
    }
  };

  useEffect(() => {
    if (cartData && cartData.data && isSelectedFamily === undefined) {
      setIsSelectedFamily(
        cartData.data.map((item) => {
          const studified = {};

          studified[`${item.id}`] = false;

          return studified;
        })
      );
    }
  }, [cartData]);

  useEffect(() => {
    if (selectedState) {
      const cities = cityData.filter((city) => city.state_id === selectedState);
      setCitiesInSelectedState(cities);
    } else {
      setCitiesInSelectedState([]);
    }
  }, [selectedState, cityData]);

  useEffect(() => {
    console.log({ productDetails });
    if (
      productDetails &&
      productDetails.seva_prices &&
      isSelectedFamily === undefined
    ) {
      setIsSelectedFamily(
        productDetails.seva_prices.map((item) => {
          const studified = {};

          studified[`${item.id}`] = false;
          return studified;
        })
      );
    }
  }, [productDetails]);

  const [refresh, setRefresh] = useState(true);

  console.log(refresh);

  const formatAddress = (addrStr) => {
    return addrStr.filter(Boolean).join(", ");
  };

  const handleKarthaSelected = (isSelected) => {
    console.log(isSelected, "isselected");
    setIsMulKartaSelected(isSelected);
  };

  const handleAncestorSelected = (isSelected) => {
    console.log(isSelected, "handleAncestorSelected");
    setIsMulAncestorSelected(isSelected);
  };

  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [selectedCountry, setSelectedCountry] = useState({
    name: "India",
    code: "IN",
    emoji: "🇮🇳",
    unicode: "U+1F1EE U+1F1F3",
    image: "IN.svg",
    dial_code: "+91",
  });
  const [agreedToTerms, setAgreedToTerms] = useState(true);
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (value === "" || (/^[0-9\b]+$/.test(value) && value.length <= 10)) {
      setPhone(value);
    }
  };

  const conditionalProceedBtn = () => {
    if (customPrice && phone.length === 10 && agreedToTerms) {
      return (
        <Button
          className="_0plu1"
          variant="outlined"
          sx={{ padding: "6px 12px!important" }}
          // style={{ opacity: phone.length === 10 && agreedToTerms ? 1 : 0.5 }}
          onClick={() => {
            // if (
            //   !familycheck.length > 1 ||
            //   !isMulKartaSelected ||
            //   !isMulAncestorSelected
            // ) {
            //   setPopUp(true);
            // } else {
            //   setActiveStep(1);
            // }
            // if (customPrice) {
            //   if (phone.length === 10 && agreedToTerms) {
            //     handleCheckout()
            //   }
            // } else {
            //   setActiveStep(1);
            // }
            if (events && !addressRequired) {
              handleCheckout();
            } else if (events && addressRequired) {
              handleSaveAddress();
            } else if (customPrice) {
              if (phone.length === 10 && agreedToTerms) {
                handleCheckout();
              }
            }
          }}
          fullWidth
        >
          Proceed to Pay
          <ChevronRight sx={{ fontSize: "30px" }} />
        </Button>
      );
    } else if (
      familycheck.length > 0 ||
      (address_1 &&
        address_2 &&
        selectedState &&
        selectedCity &&
        pincode &&
        address_name &&
        !customPrice)
    ) {
      console.log("familycheck:", address_name);
      return (
        <Button
          className="_0plu1"
          variant="outlined"
          sx={{ padding: "6px 12px!important" }}
          // style={{ opacity: phone.length === 10 && agreedToTerms ? 1 : 0.5 }}
          onClick={() => {
            // if (
            //   !familycheck.length > 1 ||
            //   !isMulKartaSelected ||
            //   !isMulAncestorSelected
            // ) {
            //   setPopUp(true);
            // } else {
            //   setActiveStep(1);
            // }
            // if (customPrice) {
            //   if (phone.length === 10 && agreedToTerms) {
            //     handleCheckout()
            //   }
            // } else {
            //   setActiveStep(1);
            // }
            if (events && !addressRequired) {
              handleCheckout();
            } else if (events && addressRequired) {
              handleSaveAddress();
            } else if (customPrice) {
              if (phone.length === 10 && agreedToTerms) {
                handleCheckout();
              }
            } else {
              handleCheckout();
            }
          }}
          fullWidth
        >
          Proceed to Pay
          <ChevronRight sx={{ fontSize: "30px" }} />
        </Button>
      );
    } else {
      return (
        <Tooltip title="Please select a family" placement="bottom">
          <div>
            <Button
              className="disabledButton1"
              variant="outlined"
              disabled
              fullWidth
            >
              Proceed to Pay
              <ChevronRight sx={{ fontSize: "30px" }} />
            </Button>
          </div>
        </Tooltip>
      );
    }
    // {
    //   familycheck.length > 0 || (customPrice && phone.length === 10 && agreedToTerms) || !addressRequired ? (
    //     <Button
    //       className="_0plu1"
    //       variant="outlined"
    //       sx={{ padding: "6px 12px!important" }}
    //       // style={{ opacity: phone.length === 10 && agreedToTerms ? 1 : 0.5 }}
    //       onClick={() => {
    //         // if (
    //         //   !familycheck.length > 1 ||
    //         //   !isMulKartaSelected ||
    //         //   !isMulAncestorSelected
    //         // ) {
    //         //   setPopUp(true);
    //         // } else {
    //         //   setActiveStep(1);
    //         // }
    //         // if (customPrice) {
    //         //   if (phone.length === 10 && agreedToTerms) {
    //         //     handleCheckout()
    //         //   }
    //         // } else {
    //         //   setActiveStep(1);
    //         // }
    //         if (events && !addressRequired) {
    //           handleCheckout()
    //         } else if (events && addressRequired) {
    //           handleSaveAddress();
    //         } else if (customPrice) {
    //           if (phone.length === 10 && agreedToTerms) {
    //             handleCheckout()
    //           }
    //         }
    //       }}
    //       fullWidth
    //     >
    //       Proceed to Pay777
    //       <ChevronRight sx={{ fontSize: "30px" }} />
    //     </Button>
    //   ) : (
    //     <Tooltip
    //       title="Please select a family"
    //       placement="bottom"
    //     >
    //       <div>
    //         <Button
    //           className="disabledButton1"
    //           variant="outlined"
    //           disabled
    //           fullWidth
    //         >
    //           Proceed to Pay888
    //           <ChevronRight sx={{ fontSize: "30px" }} />
    //         </Button>
    //       </div>
    //     </Tooltip>
    //   )
    // }
  };

  const handleCountryCodeChange = (e) => {
    const selectedDialCode = e.target.value;
    setCountryCode(selectedDialCode);
    const country = countryData.find(
      (country) => country.dial_code === selectedDialCode
    );
    setSelectedCountry(country);
  };

  return (
    <div>
      <div>
        {steps.map((label, index) => (
          <div
            className="click"
            key={label}
            style={{ display: index === activeStep ? "block" : "none" }}
          >
            {index === 0 && (
              <Container>
                <div className="add-to-cart add-to-cart-22">
                  <div className="add-to-cart-content">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <div className="add-to-cart-box add-to-cart-box11">
                          <div className="top-stepper">
                            {!customPrice ||
                              !events ||
                              (!donation && (
                                <Stepper
                                  activeStep={activeStep}
                                  alternativeLabel
                                >
                                  {steps.map((label, index) => (
                                    <Step key={label}>
                                      <StepLabel
                                        onClick={handleStepClick(index)}
                                      >
                                        {label}
                                      </StepLabel>
                                    </Step>
                                  ))}
                                </Stepper>
                              ))}
                          </div>
                          <Typography variant="h5">
                            Your Order <span>(1 item)</span>
                          </Typography>
                          <div>
                            {/* {selectedProduct && (
															<div className="add-to-cart-box-2" key={selectedProduct.id}>
																<div className="add-to-card-seva">
																	<div className="add-to-card-seva-content">
																		<Typography variant="h4">
																			Seva Name - {selectedProduct.title}
																		</Typography>
																		<p>
																			{selectedProduct.start_date ? new Date(selectedProduct.start_date).toDateString() : ''}
																		</p>
																		{selectedProduct.is_prasadam_available ? <Typography>
																			<input
																				type="checkbox"
																				id=""
																				name=""
																				value=""
																				checked={optForPrasadam}
																				onChange={() => setOptForPrasadam(prev => !prev)}
																			/>

																			<label htmlFor="">
																				Prasadam
																			</label>
																		</Typography> : undefined}
																	</div>
																	<div className="add-to-card-seva-content-2">
																		<h4>{isIndia ? '₹' : '$'}{selectedProduct.selling_price}</h4>
																		<div className="remove-btn app-new-remove-btn">
																			<Button onClick={() => removeItemFromCart(selectedProduct.id)}>
																				Remove
																			</Button>
																		</div>
																	</div>
																</div>
															</div>
														)} */}

                            <div className="add-to-cart-child-content">
                              {selectedProduct ? (
                                <div className="add-to-cart-box">
                                  <div className="add-to-cart-box-content">
                                    <div className="add-to-cart-box-main-content">
                                      {/* <div className="cart-image">
                                        <img src={cartimage}></img>
                                        <div
                                          className="delete_dustbin"
                                          onClick={() => removeItemFromCart()}
                                        ></div>
                                      </div> */}
                                      <div className="add-to-cart-box-mini-content app-new-sm-cart-box">
                                        <div>
                                          <h4 style={{ width: "100%" }}>
                                            {selectedProduct.title}
                                          </h4>
                                          <p>
                                            {selectedProduct?.start_date
                                              ? new Date(
                                                  selectedProduct?.start_date
                                                ).toDateString()
                                              : ""}
                                          </p>
                                          {/* <h6>
                                            Puja Variant -&nbsp;
                                            <span style={{ color: "#FF5B00" }}>
                                              01 Devotee
                                            </span>
                                          </h6> */}
                                        </div>
                                        {isMobile ? (
                                          <div className="add-to-cart-price">
                                            <h4>
                                              {isIndia ? "₹" : "$"}
                                              {customPrice
                                                ? customPrice
                                                : selectedProduct.selling_price}
                                            </h4>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                    {!isMobile ? (
                                      <div className="add-cart-price-remove-btn">
                                        <div className="add-to-cart-price">
                                          <h4>
                                            {true ? "₹" : "$"}
                                            {customPrice
                                              ? customPrice
                                              : selectedProduct.selling_price}
                                          </h4>
                                        </div>
                                        <div className="remove-btn">
                                          <Button
                                            onClick={() => removeItemFromCart()}
                                          >
                                            Remove
                                          </Button>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                  {donation || events ? (
                                    <div className="tipContainer">
                                      {customPrice ? (
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            lineHeight: "20px",
                                          }}
                                        >
                                          By supporting Devasmriti, you are
                                          helping us reach out to more campaigns
                                          like this and scale our impact
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            lineHeight: "20px",
                                          }}
                                        >
                                          Devasmriti remains dedicated to
                                          upholding our customs while
                                          introducing more powerful sevas to
                                          enhance your spiritual experience.
                                          Your offerings of any sort, are
                                          embraced with open arms and will be
                                          utilised in protecting dharma.
                                        </div>
                                      )}
                                      <div className="tipWrapper">
                                        {customPrice ? (
                                          <h4>Support Us By:</h4>
                                        ) : (
                                          <h4>Dakshina:</h4>
                                        )}
                                        {isCustomTip ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <input
                                              min="0"
                                              type="number"
                                              placeholder="Enter custom amount"
                                              style={{
                                                width: "190px",
                                                fontSize: "16px",
                                                padding: "5px",
                                              }}
                                              onChange={handleCustomTipChange}
                                              value={tipAmount}
                                            />
                                            <IoCloseCircle
                                              onClick={handleRevertToDropdown}
                                              size={24}
                                              style={{
                                                marginLeft: "10px",
                                                color: "#FF5B00",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <select
                                            name="tips"
                                            id="tips"
                                            style={{
                                              width: customPrice
                                                ? "150px"
                                                : "150px",
                                              fontSize: "16px",
                                              padding: "5px",
                                            }}
                                            value={tipPercentage}
                                            onChange={handleTipChange}
                                          >
                                            <option value="" disabled>
                                              Select{" "}
                                              {customPrice ? "Tip" : "Dakshina"}
                                            </option>
                                            <option value="12">
                                              12% (₹
                                              {((newprice * 12) / 100).toFixed(
                                                2
                                              )}
                                              )
                                            </option>
                                            <option value="14">
                                              14% (₹
                                              {((newprice * 14) / 100).toFixed(
                                                2
                                              )}
                                              )
                                            </option>
                                            <option value="16">
                                              16% (₹
                                              {((newprice * 16) / 100).toFixed(
                                                2
                                              )}
                                              )
                                            </option>
                                            <option value="other">Other</option>
                                          </select>
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div style={{ padding: "20px" }}>
                                  <Skeleton width={"80%"} height={10} />
                                  <Skeleton width={"60%"} height={10} />
                                  <Skeleton width={"40%"} height={10} />
                                </div>
                              )}
                              {!customPrice ? (
                                <div
                                  className=""
                                  style={{
                                    background: "white",
                                    borderBottomRightRadius: "15px",
                                    borderBottomLeftRadius: "15px",
                                  }}
                                >
                                  <BasicAccordion3
                                    familyData={familyData}
                                    setFamilyData={setFamilyData}
                                    selectedFamily={selectedFamily}
                                    setSelectedFamily={setSelectedFamily}
                                    isSelectedFamily={isSelectedFamily}
                                    seva={
                                      selectedProduct ? selectedProduct : {}
                                    }
                                    setIsSelectedFamily={setIsSelectedFamily}
                                    setFamilyCheck={setFamilyCheck}
                                    familycheck={familycheck}
                                    setRefresh={setRefresh}
                                    handleCheckout={handleCheckout}
                                    onKarthaSelected={handleKarthaSelected}
                                    onAncestorSelected={handleAncestorSelected}
                                    customPrice={customPrice}
                                    events={events}
                                    individual={individual}
                                  />
                                </div>
                              ) : (
                                <div>
                                  {/* <div>Contact Details</div> */}
                                  <div style={{ padding: "20px" }}>
                                    <Typography variant="h6">
                                      Profile Details
                                    </Typography>

                                    <div>
                                      <div>
                                        <Grid container spacing={2}>
                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                          >
                                            <div className="myfamily-forms-1">
                                              <FormLabel>
                                                Phone Number
                                              </FormLabel>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <FormControl
                                                  variant="outlined"
                                                  style={{
                                                    marginRight: 8,
                                                    width: "40%",
                                                  }}
                                                >
                                                  {/* <InputLabel id="country-code-label">Code</InputLabel> */}
                                                  <Select
                                                    labelId="country-code-label"
                                                    id="country-code"
                                                    value={countryCode}
                                                    onChange={
                                                      handleCountryCodeChange
                                                    }
                                                    // label="Code"
                                                  >
                                                    {countryData.map(
                                                      (country) => (
                                                        <MenuItem
                                                          value={
                                                            country.dial_code
                                                          }
                                                        >
                                                          <span
                                                            role="img"
                                                            aria-label={
                                                              country.name
                                                            }
                                                            style={{
                                                              marginRight: 8,
                                                              fontSize: "1.5em",
                                                            }}
                                                          >
                                                            {country.emoji}
                                                          </span>
                                                          {country.dial_code}
                                                        </MenuItem>
                                                      )
                                                    )}
                                                    {/* Add more country codes as needed */}
                                                  </Select>
                                                </FormControl>
                                                <TextField
                                                  type="text"
                                                  id="phone"
                                                  value={phone}
                                                  onChange={handlePhoneChange}
                                                  inputProps={{ maxLength: 10 }}
                                                  placeholder="1234567890"
                                                  // variant="outlined"
                                                  // label="Phone"
                                                />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                          >
                                            <div className="myfamily-forms-1">
                                              <FormLabel>Full Name</FormLabel>

                                              <TextField
                                                type="text"
                                                id="fname"
                                                value={firstName}
                                                onChange={(e) =>
                                                  setFirstName(e.target.value)
                                                }
                                              />
                                            </div>
                                          </Grid>

                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                          >
                                            <div className="myfamily-forms-1">
                                              <FormLabel>Email</FormLabel>

                                              <TextField
                                                type="email"
                                                id="email"
                                                value={email}
                                                onChange={handleEmailChange}
                                                error={!!emailError}
                                                helperText={emailError}
                                                // error={emailError.email}
                                              />
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </div>
                                      <div style={{ marginTop: "20px" }}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={agreedToTerms}
                                              onChange={(e) =>
                                                setAgreedToTerms(
                                                  e.target.checked
                                                )
                                              }
                                              name="terms"
                                              color="primary"
                                            />
                                          }
                                          label={
                                            <span
                                              style={{
                                                width: "100%",
                                                display: "flex",
                                              }}
                                            >
                                              By continuing, you are agreeing
                                              to&nbsp;
                                              <a
                                                href="/terms"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                {" "}
                                                Terms of Use{" "}
                                              </a>
                                              &nbsp;and&nbsp;
                                              <a
                                                href="/Privacy"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                {" "}
                                                Privacy Policy
                                              </a>
                                              .
                                            </span>
                                          }
                                        />
                                      </div>
                                      {/* <div className="myfamily-forms-btn">
      <Button onClick={saveProfileData}>Save</Button>
    </div> */}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {/* <div className="">
														<BasicAccordion3
															familyData={familyData}
															setFamilyData={setFamilyData}
															selectedFamily={selectedFamily}
															setSelectedFamily={setSelectedFamily}
															isSelectedFamily={isSelectedFamily}
															seva={selectedProduct ? selectedProduct : {}}
															setIsSelectedFamily={setIsSelectedFamily}
															setFamilyCheck={setFamilyCheck}
															familycheck={familycheck}
															setRefresh={setRefresh}
															handleCheckout={handleCheckout}
														/>
													</div> */}
                          {events || individual ? (
                            <div
                              className={
                                window.innerWidth > 700
                                  ? "prasadAddress"
                                  : "prasadAddressMobile"
                              }
                            >
                              {!individual && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    paddingBottom: "20px",
                                  }}
                                >
                                  <h4>Do you want to get puja prasad?</h4>
                                  <div>
                                    <button
                                      className={
                                        addressRequired
                                          ? "yesBtnSelected"
                                          : "yesBtn"
                                      }
                                      onClick={(e) => setAddressRequired(true)}
                                    >
                                      Yes
                                    </button>
                                    <button
                                      className={
                                        addressRequired
                                          ? "yesBtn"
                                          : "yesBtnSelected"
                                      }
                                      onClick={(e) => setAddressRequired(false)}
                                    >
                                      No
                                    </button>
                                  </div>
                                </div>
                              )}
                              {addressRequired && (
                                <div>
                                  <div>
                                    <Typography variant="h6">
                                      {!individual && (
                                        <FormLabel>
                                          Prasad of worship will be sent within
                                          8-10 days after completion of puja
                                        </FormLabel>
                                      )}
                                      {/* {isEditMode
                                      ? "Edit Address"
                                      : "Address Details"} */}
                                    </Typography>

                                    <div
                                      className="billing-forms"
                                      style={{
                                        paddingTop: individual ? "10px" : "",
                                      }}
                                    >
                                      <Grid container spacing={2}>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={12}
                                          xl={12}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>
                                              Address Line 1
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            <TextField
                                              value={address_1}
                                              error={fieldErrors.address_1}
                                              onChange={(e) =>
                                                setAddress1(e.target.value)
                                              }
                                              id="demo-helper-text-aligned"
                                              // placeholder="KPHB Phase 1, Kukatpally, Hyderabad"
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={12}
                                          xl={12}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>
                                              Address Line 2
                                            </FormLabel>
                                            <TextField
                                              value={address_2}
                                              onChange={(e) =>
                                                setAddress2(e.target.value)
                                              }
                                              id="demo-helper-text-aligned"
                                              //  placeholder="KPHB Phase 1, Kukatpally, Hyderabad"
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-2">
                                            <FormLabel>
                                              State
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            <Select
                                              id="state"
                                              value={selectedState}
                                              error={fieldErrors.selectedState}
                                              onChange={(e) =>
                                                setSelectedState(e.target.value)
                                              }
                                            >
                                              <MenuItem disabled value="">
                                                Select ur State
                                              </MenuItem>
                                              {stateData.map((state) => (
                                                <MenuItem
                                                  key={state.id}
                                                  value={state.id}
                                                >
                                                  {state.name}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-2">
                                            <FormLabel>
                                              City
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            {/* <Select
                                              id="city"
                                              value={selectedCity}
                                              onChange={(e) =>
                                                setSelectedCity(e.target.value)
                                              }
                                              error={fieldErrors.city_id}
                                            >
                                              <MenuItem disabled value="">
                                                Select ur City
                                              </MenuItem>
                                              {cityData.map((city) => (
                                                <MenuItem
                                                  key={city.id}
                                                  value={city.id}
                                                >
                                                  {city.name}
                                                </MenuItem>
                                              ))}
                                            </Select> */}

                                            <TextField
                                              id="city"
                                              value={selectedCity}
                                              error={fieldErrors.selectedCity}
                                              onChange={(e) =>
                                                setSelectedCity(e.target.value)
                                              }
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>
                                              Pin Code
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            <TextField
                                              value={pincode}
                                              error={fieldErrors.pincode}
                                              onChange={(e) =>
                                                setPincode(e.target.value)
                                              }
                                              id="number"
                                              // placeholder="500072"
                                            />
                                          </div>
                                        </Grid>
                                        {/* <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                      >
                                        <div className="myfamily-forms-1">
                                          <FormLabel>
                                            Whatsapp No
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </FormLabel>
                                          <TextField
                                            value={whatsup_no}
                                            error={fieldErrors.whatsup_no}
                                            onChange={(e) =>
                                              setWhatsupNo(e.target.value)
                                            }
                                            id="number"
                                          // placeholder="+91 82107 16196"
                                          />
                                        </div>
                                      </Grid> */}
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>
                                              Address Name{" "}
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>

                                            {isEditMode ? (
                                              <>
                                                <Select
                                                  id="addressName"
                                                  value={address_name}
                                                  error={
                                                    fieldErrors.address_name
                                                  }
                                                  onChange={
                                                    handleAddressNameChange
                                                  }
                                                  sx={{
                                                    marginBottom: "20px",
                                                  }}
                                                >
                                                  <MenuItem disabled value="">
                                                    Select an Address Name
                                                  </MenuItem>
                                                  {addressNameOptions.map(
                                                    (option) => (
                                                      <MenuItem
                                                        key={option.id}
                                                        value={option.id}
                                                      >
                                                        {option.name}
                                                      </MenuItem>
                                                    )
                                                  )}
                                                </Select>

                                                {address_name === "other" && (
                                                  <TextField
                                                    value={otherAddressName}
                                                    onChange={(e) =>
                                                      setOtherAddressName(
                                                        e.target.value
                                                      )
                                                    }
                                                    id="otherAddressName"
                                                    placeholder="Enter Address Name"
                                                    error={
                                                      fieldErrors.otherAddressName
                                                    }
                                                  />
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                <Select
                                                  id="addressName"
                                                  value={address_name}
                                                  error={
                                                    fieldErrors.address_name
                                                  }
                                                  onChange={
                                                    handleAddressNameChange
                                                  }
                                                  sx={{
                                                    marginBottom: "20px",
                                                  }}
                                                >
                                                  <MenuItem disabled value="">
                                                    Select an Address Name
                                                  </MenuItem>
                                                  {addressNameOptions.map(
                                                    (option) => (
                                                      <MenuItem
                                                        key={option.id}
                                                        value={option.id}
                                                      >
                                                        {option.name}
                                                      </MenuItem>
                                                    )
                                                  )}
                                                </Select>

                                                {address_name === "other" && (
                                                  <TextField
                                                    value={otherAddressName}
                                                    onChange={(e) =>
                                                      setOtherAddressName(
                                                        e.target.value
                                                      )
                                                    }
                                                    id="otherAddressName"
                                                    placeholder="Enter Address Name"
                                                    error={
                                                      fieldErrors.otherAddressName
                                                    }
                                                  />
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                    {/* <div className="myfamily-forms-btn">
                                  <Button onClick={handleSaveAddress}>
                                    {isEditMode
                                      ? "Update Address"
                                      : "Save Address"}
                                  </Button>
                                </div> */}
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          <div>
                            {/* // Continue button */}
                            {!isMobile && !customPrice && (
                              <>{conditionalSubmitBtn()}</>
                            )}

                            {/* {popUp && (
                              <Dialog open={popUp} onClose={handleClosePop}>
                                 <DialogTitle>Dialog Title</DialogTitle> 
                                <DialogContent>
                                  <DialogContentText>
                                    Kartha is the eligible male person in your
                                    family to perform this ritual. You can't
                                    select multiple kartha options!!😓
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    onClick={handleClosePop}
                                    className="_0plu"
                                    autoFocus
                                  >
                                    OK
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            )} */}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className="order-summary">
                          <div className="order-summary-title">
                            <h2>Order Summary</h2>
                          </div>
                          <div className="order-summary-list">
                            {extrachargesLabel && (
                              <>
                                <ul>
                                  <li className="sub-total">Sub Total</li>
                                  <li>
                                    {isIndia ? "₹" : "$"}
                                    {customPrice
                                      ? customPrice
                                      : selectedProduct.selling_price}
                                  </li>
                                </ul>
                                {extrachargesLabel !== "0" && (
                                  <div>
                                    {/* <ul>
																		<li className="sub-total" style={{ fontWeight: "bold", fontSize: "13px" }}>Extra charges</li>
																	</ul> */}

                                    <ul>
                                      <li className="sub-total">
                                        {extrachargesLabel}
                                      </li>
                                      <li>
                                        {isIndia ? "₹" : "$"}
                                        {(
                                          (selectedProduct.selling_price *
                                            extrachargesPercentage) /
                                          100
                                        ).toFixed(2)}
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </>
                            )}
                            {addressRequired && !customPrice && (
                              <ul>
                                <li className="sub-total">Shipping Charge</li>
                                <li>
                                  {isIndia ? "₹" : "$"}
                                  70.00
                                </li>
                              </ul>
                            )}
                            {tipAmount !== null && tipAmount > 0 && (
                              <ul>
                                <li className="sub-total">
                                  {customPrice ? "Tip" : "Dakshina"}
                                </li>
                                <li>
                                  {isIndia ? "₹" : "$"}
                                  {tipAmount}
                                </li>
                              </ul>
                            )}
                            <ul>
                              <li className="sub-total">Total</li>
                              <li>
                                {isIndia ? "₹" : "$"}
                                {calculateTotal()}
                              </li>
                            </ul>
                          </div>
                          {!isMobile && (
                            <>
                              {familycheck.length > 0 ||
                              customPrice ||
                              individual ? (
                                <Button
                                  className="_0plu"
                                  variant="outlined"
                                  sx={{
                                    borderRadius: "8px!important",
                                  }}
                                  style={{
                                    opacity: !individual
                                      ? customPrice
                                        ? phone.length === 10 && agreedToTerms
                                          ? 1
                                          : 0.5
                                        : 1
                                      : address_1 &&
                                          address_2 &&
                                          selectedState &&
                                          selectedCity &&
                                          pincode &&
                                          address_name
                                        ? 1
                                        : 0.5,
                                  }}
                                  onClick={() => {
                                    // if (isKartaAndAncestor) {
                                    //   if (
                                    //     !isMulKartaSelected ||
                                    //     !isMulAncestorSelected
                                    //   ) {
                                    //     setPopUp(true);
                                    //   } else {
                                    //     setActiveStep(1);
                                    //   }
                                    // } else {
                                    //   setActiveStep(1);
                                    // }
                                    if (customPrice) {
                                      if (
                                        phone.length === 10 &&
                                        agreedToTerms
                                      ) {
                                        handleCheckout();
                                      }
                                    } else if (
                                      individual &&
                                      address_1 &&
                                      address_2 &&
                                      selectedState &&
                                      selectedCity &&
                                      pincode &&
                                      address_name
                                    ) {
                                      handleSaveAddress();
                                    } else {
                                      handleCheckout();
                                    }
                                    // TODO: Check and confirm if its affecting other flows
                                    // else {
                                    //   setActiveStep(1);
                                    // }
                                  }}
                                  fullWidth
                                >
                                  Proceed to Pay
                                  <ChevronRight sx={{ fontSize: "30px" }} />
                                </Button>
                              ) : (
                                <Tooltip
                                  title="Please select a family"
                                  placement="bottom"
                                >
                                  <div>
                                    <Button
                                      className="disabledButton"
                                      variant="outlined"
                                      disabled
                                      fullWidth
                                      sx={{
                                        color: "white!important",
                                        backgroundColor: "lightgray",
                                        borderRadius: "8px!important",
                                      }}
                                    >
                                      Proceed to Pay
                                      <ChevronRight sx={{ fontSize: "30px" }} />
                                    </Button>
                                  </div>
                                </Tooltip>
                              )}
                            </>
                          )}
                          {isMobile && (
                            <Box
                              sx={{
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              {conditionalProceedBtn()}
                            </Box>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Container>
            )}
            {index === 1 && (
              <Container>
                <div className="add-to-cart add-to-cart-22">
                  <div className="add-to-cart-content">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <div className="add-to-cart-box add-to-cart-box11">
                          <div className="top-stepper">
                            <Button
                              className="_9hyx"
                              variant="outlined"
                              onClick={() => setActiveStep(0)}
                            >
                              <IoMdArrowRoundBack />
                            </Button>
                            <Stepper activeStep={activeStep} alternativeLabel>
                              {steps.map((label, index) => (
                                <Step key={label}>
                                  <StepLabel onClick={handleStepClick(index)}>
                                    {label}
                                  </StepLabel>
                                </Step>
                              ))}
                            </Stepper>
                          </div>
                          <div
                            style={{
                              background: "#fff",
                              padding: "16px",
                              boxShadow: "0px 4px 15px 0px #0000000D",
                              borderRadius: "15px",
                            }}
                          >
                            <Typography variant="h5">
                              Your Order <span>( 1 items)</span>
                            </Typography>

                            <Box
                              sx={{
                                marginBottom: "34px",
                                // paddingBottom: "20px",
                              }}
                              key="uniqueKeyForAllSevas"
                            >
                              <Box
                                sx={{
                                  marginTop: "10px",
                                  border: "2px solid rgba(255, 91, 0, 0.4)",
                                  marginBottom: "20px",
                                  borderRadius: "10px",
                                }}
                              >
                                <Accordion
                                  defaultExpanded
                                  sx={{
                                    boxShadow: "none",
                                    padding: "10px",
                                    background: "transparent",
                                  }}
                                  className="all-seva-accordion"
                                >
                                  <AccordionSummary
                                    expandIcon={
                                      <ExpandMoreIcon
                                        sx={{
                                          color: "#FF5B00",
                                          fontSize: "30px",
                                        }}
                                      />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                      paddingLeft: "0 !important",
                                    }}
                                  >
                                    <Box sx={{ width: "100%" }}>
                                      <Typography
                                        component="h3"
                                        sx={{
                                          marginLeft: "10px",
                                          textTransform: "capitalize",
                                          fontFamily: "Objectivity",
                                          fontWeight: {
                                            xs: 700,
                                            sm: 700,
                                          },
                                          lineHeight: {
                                            xs: "16px",
                                            sm: "22.4px",
                                          },
                                          textAlign: "left",
                                          color: {
                                            xs: "#2C2C2C",
                                            sm: "#4F4F4F",
                                          },
                                        }}
                                      >
                                        All Sevas
                                      </Typography>
                                      <Typography
                                        sx={{
                                          marginLeft: "10px",
                                          marginTop: "5px",
                                          fontFamily: "Objectivity",
                                          fontSize: {
                                            xs: "11px",
                                            sm: "13px",
                                          },
                                          fontWeight: {
                                            xs: 500,
                                            sm: 500,
                                          },
                                          lineHeight: {
                                            xs: "16px",
                                            sm: "12.3px",
                                          },
                                          textAlign: "left",
                                          color: {
                                            xs: "#747474",
                                            sm: "#505050",
                                          },
                                        }}
                                      >
                                        You can check your selected sevas
                                      </Typography>
                                    </Box>
                                  </AccordionSummary>

                                  <AccordionDetails
                                    sx={{
                                      borderTop: "1px solid rgba(0, 0, 0, 0.2)",
                                      paddingTop: "16px",
                                    }}
                                  >
                                    {console.log("ff:", selectedProduct)}
                                    {selectedProduct && (
                                      <Box
                                        key={"kk"}
                                        sx={{
                                          display: "flex",
                                          height: isMobile ? "unset" : "unset",
                                          position: "relative",
                                        }}
                                      >
                                        {/* <div className="cart-image">
                                          <img
                                            src={cartimage}
                                            alt="Seva Image"
                                          />
                                          <div
                                            className="delete_dustbin"
                                            onClick={() => removeItemFromCart()}
                                          ></div>
                                        </div> */}
                                        <div className="add-to-cart-box-mini-content app-new-sm-cart-box">
                                          <div>
                                            <h4 style={{ width: "100%" }}>
                                              {selectedProduct?.title}
                                            </h4>
                                            <p>
                                              {selectedProduct?.created_at
                                                ? new Date(
                                                    selectedProduct?.created_at
                                                  ).toDateString()
                                                : ""}
                                            </p>
                                            {/* <h6>
                                              Puja Variant -&nbsp;
                                              <span
                                                style={{ color: "#FF5B00" }}
                                              >
                                                01 Devotee
                                              </span>
                                            </h6> */}
                                          </div>
                                          {isMobile ? (
                                            <div className="add-to-cart-price">
                                              <h4>
                                                {/* To do: need to fix it */}
                                                {true ? "₹" : "$"}
                                                {customPrice
                                                  ? customPrice
                                                  : selectedProduct?.selling_price}
                                              </h4>
                                            </div>
                                          ) : null}
                                        </div>
                                        {!isMobile && (
                                          <div className="add-cart-price-remove-btn">
                                            <div className="add-to-cart-price">
                                              <h4>
                                                ₹{" "}
                                                {customPrice
                                                  ? customPrice
                                                  : selectedProduct?.selling_price}
                                              </h4>
                                            </div>
                                            <div className="remove-btn">
                                              <Button
                                                onClick={() =>
                                                  removeItemFromCart()
                                                }
                                              >
                                                Remove
                                              </Button>
                                            </div>
                                          </div>
                                        )}
                                      </Box>
                                    )}
                                  </AccordionDetails>
                                </Accordion>
                              </Box>
                            </Box>
                            {!customPrice && (
                              <>
                                <div className="select-delivery-address">
                                  <div className="deliver-address-content">
                                    <div className="deliver-address-title">
                                      <h3>Select Delivery Address</h3>
                                    </div>
                                  </div>
                                </div>
                                {addresses.map((address, index) => (
                                  // <div
                                  //   className="select-address-home-2"
                                  //   key={address.id}
                                  // >
                                  //   <div className="select-address-home">
                                  //     <div className="select-address-box">
                                  //       <div className="address">
                                  //       <Accordion defaultExpanded>
                                  //         <AccordionSummary
                                  //           expandIcon={
                                  //             <ExpandMoreIcon sx={{ color: "#FF5B00", fontSize: "30px" }} />
                                  //           }
                                  //           aria-controls="panel1a-content"
                                  //           id="panel1a-header"
                                  //         >
                                  //         <div className="home">
                                  //           <Checkbox
                                  //             checked={
                                  //               selectedAddressId === address.id
                                  //             }
                                  //             onChange={() =>
                                  //               setSelectedAddressId(address.id)
                                  //             }
                                  //           />
                                  //           <h3>{address.address_name.charAt(0).toUpperCase() + address.address_name.slice(1)}</h3>
                                  //         </div>
                                  //         </AccordionSummary>
                                  //         <AccordionDetails>
                                  //         <p>
                                  //           {`${address.fname}, ${address.lname}`}
                                  //           {address.address_1},{address.address_2},
                                  //           {address.city ? address.city.name : ""},
                                  //           {address.state
                                  //             ? address.state.name
                                  //             : ""}
                                  //           ,
                                  //           {address.country
                                  //             ? address.country.name
                                  //             : ""}
                                  //         </p>
                                  //         </AccordionDetails>
                                  //         </Accordion>
                                  //       </div>
                                  //     </div>
                                  //   </div>
                                  // </div>

                                  <Box
                                    sx={{
                                      marginBottom:
                                        index === addresses.length - 1
                                          ? "20px"
                                          : "10px",
                                      paddingBottom:
                                        index === addresses?.length - 1
                                          ? "20px"
                                          : "unset",
                                      borderBottom:
                                        index === addresses?.length - 1
                                          ? "1px solid rgba(0, 0, 0, 0.2)"
                                          : "none",
                                    }}
                                    key={address.id}
                                  >
                                    <Box
                                      sx={{
                                        marginTop: "30px",
                                        border:
                                          "2px solid rgba(255, 91, 0, 0.4)",
                                        marginBottom: "20px",
                                        borderTopLeftRadius: "10px",
                                        borderTopRightRadius: "10px",
                                        borderBottomLeftRadius: "10px",
                                        borderBottomRightRadius: "10px",
                                      }}
                                    >
                                      <Accordion
                                        defaultExpanded
                                        sx={{
                                          boxShadow: "none",
                                          padding: "10px",
                                          background: "transparent",
                                        }}
                                      >
                                        <AccordionSummary
                                          expandIcon={
                                            <ExpandMoreIcon
                                              sx={{
                                                color: "#FF5B00",
                                                fontSize: "30px",
                                              }}
                                            />
                                          }
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                selectedAddressId === address.id
                                              }
                                              onChange={() =>
                                                setSelectedAddressId(address.id)
                                              }
                                              sx={{
                                                padding: 0,
                                                color:
                                                  selectedAddressId ===
                                                  address?.id
                                                    ? "#ff5b00"
                                                    : "#988E8E!important",
                                                "&.Mui-checked": {
                                                  color: "#ff5b00",
                                                },
                                              }}
                                            />
                                            <Typography
                                              component="h3"
                                              sx={{
                                                marginLeft: "10px",
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {address.address_name}
                                            </Typography>
                                          </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Grid
                                            container
                                            alignItems="center"
                                            spacing={1}
                                            sx={{
                                              padding: isMobile
                                                ? "unset"
                                                : "0 34px",
                                            }}
                                          >
                                            {isMobile ? (
                                              <Grid item>
                                                <img
                                                  src={PencilIcon}
                                                  width="30"
                                                  height="30"
                                                  variant="outlined"
                                                  onClick={() =>
                                                    handleOpenEditAddressModal(
                                                      address
                                                    )
                                                  }
                                                  sx={{
                                                    color: "#ff5b00",
                                                    cursor: "pointer",
                                                    fill: "none",
                                                    stroke: "#FF5B00",
                                                  }}
                                                />
                                              </Grid>
                                            ) : null}
                                            <Grid item xs>
                                              <Typography
                                                sx={{
                                                  whiteSpace: "normal",
                                                }}
                                              >
                                                {formatAddress([
                                                  address?.fname
                                                    ? address.fname
                                                    : "",
                                                  address?.lname
                                                    ? address.lname
                                                    : "",
                                                  address?.address_1
                                                    ? address.address_1
                                                    : "",
                                                  address?.address_2
                                                    ? address.address_2
                                                    : "",
                                                  address?.city
                                                    ? address.city.name
                                                    : "",
                                                  address?.state
                                                    ? address.state.name
                                                    : "",
                                                  address?.country
                                                    ? address.country.name
                                                    : "",
                                                ])}
                                              </Typography>
                                              {!isMobile ? (
                                                <div className="edit-btn">
                                                  <Button
                                                    onClick={() =>
                                                      handleOpenEditAddressModal(
                                                        address
                                                      )
                                                    }
                                                    variant="outlined"
                                                    sx={{
                                                      marginTop: "30px",
                                                      marginLeft:
                                                        "unset!important",
                                                      "&:hover": {
                                                        borderColor: "unset",
                                                      },
                                                    }}
                                                  >
                                                    Edit
                                                  </Button>
                                                </div>
                                              ) : null}
                                            </Grid>
                                          </Grid>
                                        </AccordionDetails>
                                      </Accordion>
                                    </Box>
                                  </Box>
                                ))}
                              </>
                            )}

                            <div className="add-new-address">
                              <Button
                                disableRipple
                                disableElevation
                                onClick={handleOpenAddAddressModal}
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "transparent",
                                  },
                                }}
                              >
                                <span>
                                  <AddIcon sx={{ marginRight: "5px" }} />
                                </span>
                                Add New Address
                              </Button>
                            </div>
                            <Typography
                              sx={{
                                fontFamily: "Objectivity",
                                fontSize: "12px",
                                fontWeight: 500,
                                lineHeight: "20px",
                                textAlign: "left",
                                color: "#24292E",
                                margin: "20px 0",
                                textAlign: "justify",
                              }}
                            >
                              Your personal data will be used to process your
                              order, support your experience throughout this
                              website, and for other purposes described in our{" "}
                              <Link
                                to="/Privacy"
                                style={{ display: "inline", color: "#ff5b00" }}
                              >
                                Privacy Policy
                              </Link>
                            </Typography>
                            <Dialog
                              open={isAddAddressModalOpen}
                              onClose={handleCloseAddAddressModal}
                              fullWidth
                              maxWidth="md"
                            >
                              <DialogContent style={{ padding: "20px" }}>
                                <div>
                                  <div>
                                    <Typography variant="h6">
                                      {isEditMode
                                        ? "Edit Address"
                                        : "Address Details"}
                                    </Typography>

                                    <div className="billing-forms">
                                      <Grid container spacing={2}>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>
                                              First Name
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            <TextField
                                              id="name"
                                              // placeholder="Akash"
                                              value={fname}
                                              onChange={(e) =>
                                                setFname(e.target.value)
                                              }
                                              error={fieldErrors.fname}
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>Last Name</FormLabel>
                                            <TextField
                                              id="name"
                                              value={lname}
                                              onChange={(e) =>
                                                setLname(e.target.value)
                                              }
                                              // placeholder="Gupta"
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>
                                              Phone No
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            <TextField
                                              value={phone_no}
                                              onChange={(e) =>
                                                setPhoneNo(e.target.value)
                                              }
                                              id="outlined-number"
                                              type="tel"
                                              // placeholder="+91 82107 16196"
                                              error={fieldErrors.phone_no}
                                              InputLabelProps={{ shrink: true }}
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>
                                              Email ID
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            <TextField
                                              value={email}
                                              error={fieldErrors.email}
                                              onChange={(e) =>
                                                setEmail(e.target.value)
                                              }
                                              id="email"
                                              // placeholder="akashgupta@gmail.com"
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={12}
                                          xl={12}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>
                                              Address Line 1
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            <TextField
                                              value={address_1}
                                              error={fieldErrors.address_1}
                                              onChange={(e) =>
                                                setAddress1(e.target.value)
                                              }
                                              id="demo-helper-text-aligned"
                                              // placeholder="KPHB Phase 1, Kukatpally, Hyderabad"
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={12}
                                          xl={12}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>
                                              Address Line 2
                                            </FormLabel>
                                            <TextField
                                              value={address_2}
                                              onChange={(e) =>
                                                setAddress2(e.target.value)
                                              }
                                              id="demo-helper-text-aligned"
                                              //  placeholder="KPHB Phase 1, Kukatpally, Hyderabad"
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-2">
                                            <FormLabel>
                                              State
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            <Select
                                              id="state"
                                              value={selectedState}
                                              error={fieldErrors.selectedState}
                                              onChange={(e) =>
                                                setSelectedState(e.target.value)
                                              }
                                            >
                                              <MenuItem disabled value="">
                                                Select ur State
                                              </MenuItem>
                                              {stateData.map((state) => (
                                                <MenuItem
                                                  key={state.id}
                                                  value={state.id}
                                                >
                                                  {state.name}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-2">
                                            <FormLabel>
                                              City
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            {/* <Select
                                              id="city"
                                              value={selectedCity}
                                              onChange={(e) =>
                                                setSelectedCity(e.target.value)
                                              }
                                              error={fieldErrors.city_id}
                                            >
                                              <MenuItem disabled value="">
                                                Select ur City
                                              </MenuItem>
                                              {cityData.map((city) => (
                                                <MenuItem
                                                  key={city.id}
                                                  value={city.id}
                                                >
                                                  {city.name}
                                                </MenuItem>
                                              ))}
                                            </Select> */}

                                            <TextField
                                              id="city"
                                              value={selectedCity}
                                              error={fieldErrors.selectedCity}
                                              onChange={(e) =>
                                                setSelectedCity(e.target.value)
                                              }
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>
                                              Pin Code
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            <TextField
                                              value={pincode}
                                              error={fieldErrors.pincode}
                                              onChange={(e) =>
                                                setPincode(e.target.value)
                                              }
                                              id="number"
                                              // placeholder="500072"
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>
                                              Whatsapp No
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            <TextField
                                              value={whatsup_no}
                                              error={fieldErrors.whatsup_no}
                                              onChange={(e) =>
                                                setWhatsupNo(e.target.value)
                                              }
                                              id="number"
                                              // placeholder="+91 82107 16196"
                                            />
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <div className="myfamily-forms-1">
                                            <FormLabel>
                                              Address Name{" "}
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </FormLabel>
                                            {/* Dropdown for known address names */}
                                            {isEditMode ? (
                                              <>
                                                <Select
                                                  id="addressName"
                                                  value={address_name}
                                                  error={
                                                    fieldErrors.address_name
                                                  }
                                                  onChange={
                                                    handleAddressNameChange
                                                  }
                                                  sx={{
                                                    marginBottom: "20px",
                                                  }}
                                                >
                                                  <MenuItem disabled value="">
                                                    Select an Address Name
                                                  </MenuItem>
                                                  {addressNameOptions.map(
                                                    (option) => (
                                                      <MenuItem
                                                        key={option.id}
                                                        value={option.id}
                                                      >
                                                        {option.name}
                                                      </MenuItem>
                                                    )
                                                  )}
                                                </Select>

                                                {address_name === "other" && (
                                                  <TextField
                                                    value={otherAddressName}
                                                    onChange={(e) =>
                                                      setOtherAddressName(
                                                        e.target.value
                                                      )
                                                    }
                                                    id="otherAddressName"
                                                    placeholder="Enter Address Name"
                                                    error={
                                                      fieldErrors.otherAddressName
                                                    }
                                                  />
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                <Select
                                                  id="addressName"
                                                  value={address_name}
                                                  error={
                                                    fieldErrors.address_name
                                                  }
                                                  onChange={
                                                    handleAddressNameChange
                                                  }
                                                  sx={{
                                                    marginBottom: "20px",
                                                  }}
                                                >
                                                  <MenuItem disabled value="">
                                                    Select an Address Name
                                                  </MenuItem>
                                                  {addressNameOptions.map(
                                                    (option) => (
                                                      <MenuItem
                                                        key={option.id}
                                                        value={option.id}
                                                      >
                                                        {option.name}
                                                      </MenuItem>
                                                    )
                                                  )}
                                                </Select>

                                                {address_name === "other" && (
                                                  <TextField
                                                    value={otherAddressName}
                                                    onChange={(e) =>
                                                      setOtherAddressName(
                                                        e.target.value
                                                      )
                                                    }
                                                    id="otherAddressName"
                                                    placeholder="Enter Address Name"
                                                    error={
                                                      fieldErrors.otherAddressName
                                                    }
                                                  />
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                    <div className="myfamily-forms-btn">
                                      <Button onClick={handleSaveAddress}>
                                        {isEditMode
                                          ? "Update Address"
                                          : "Save Address"}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </DialogContent>
                            </Dialog>
                          </div>
                          {!isMobile && (
                            <div className="add-family-details-btn">
                              <Button
                                onClick={handleCheckout}
                                disableRipple
                                disableElevation
                              >
                                <VerifiedUserIcon />
                                <div className="log-in confirmation-btn">
                                  <Button
                                    className="login-btn"
                                    variant="outlined"
                                  >
                                    Place Order {isIndia ? "₹" : "$"}
                                    {calculateTotal()}
                                  </Button>
                                  <BootstrapDialog
                                    onClose={handleClose}
                                    aria-labelledby="customized-dialog-title"
                                    open={open}
                                  >
                                    <DialogTitle
                                      sx={{ m: 0, p: 2 }}
                                      id="customized-dialog-title"
                                    ></DialogTitle>
                                    <IconButton
                                      aria-label="close"
                                      onClick={handleClose}
                                      sx={{
                                        position: "absolute",
                                        right: 8,
                                        top: 8,
                                        color: (theme) =>
                                          theme.palette.grey[500],
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                    <DialogContent>
                                      <div className="welcome-back">
                                        <div className="ganesh-leaf">
                                          <img src={leaf} />
                                        </div>
                                        <div className="welcome-back-content">
                                          <img src={ganesh} />
                                          <h4>Booking is Successful</h4>
                                          <h5>
                                            We have successfully received your
                                            details
                                          </h5>
                                          <p>
                                            You will get a confirmation call or
                                            WhatsApp within 12 hrs of booking
                                          </p>
                                        </div>

                                        <div className="send-otp verify-otp">
                                          <Link to="/home">
                                            <Button
                                              disableRipple
                                              disableElevation
                                              variant="contained"
                                              className="app-btn app-btn-primary has-icon-end"
                                            >
                                              <span className="app-btn-text">
                                                Return to Homepage
                                              </span>
                                            </Button>
                                          </Link>
                                        </div>
                                      </div>
                                    </DialogContent>
                                  </BootstrapDialog>
                                </div>
                                <KeyboardArrowRightIcon />
                              </Button>
                            </div>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div className="order-summary">
                          {/* <div className="coupons"></div> */}
                          <div className="order-summary-title">
                            <h2>Order Summary</h2>
                          </div>
                          <div className="order-summary-list">
                            {extrachargesLabel && (
                              <>
                                <ul>
                                  <li className="sub-total">Sub Total</li>
                                  <li>
                                    {isIndia ? "₹" : "$"}
                                    {customPrice
                                      ? customPrice
                                      : selectedProduct.selling_price}
                                  </li>
                                </ul>
                                {extrachargesLabel !== "0" && (
                                  <div>
                                    {/* <ul>
																		<li className="sub-total" style={{ fontWeight: "bold", fontSize: "13px" }}>Extra charges</li>
																	</ul> */}

                                    <ul>
                                      <li className="sub-total">
                                        {extrachargesLabel}
                                      </li>
                                      <li>
                                        {isIndia ? "₹" : "$"}
                                        {(
                                          (selectedProduct.selling_price *
                                            extrachargesPercentage) /
                                          100
                                        ).toFixed(2)}
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </>
                            )}
                            <ul>
                              <li className="sub-total">Total</li>
                              <li>
                                {isIndia ? "₹" : "$"}
                                {calculateTotal()}
                              </li>
                            </ul>

                            {/* {isMobile && ( */}
                            <div className="log-in confirmation-btn">
                              <Button
                                onClick={handleCheckout}
                                className="_0plu1"
                                variant="outlined"
                                fullWidth
                                disableRipple
                                disableElevation
                                sx={{
                                  padding: {
                                    xs: "6px 40px!important",
                                    sm: "10px 40px!important",
                                  },
                                  borderRadius: {
                                    xs: "15px!important",
                                    sm: "8px!important",
                                  },
                                }}
                              >
                                Proceed to Pay
                                <ChevronRight sx={{ fontSize: "30px" }} />
                              </Button>
                              <BootstrapDialog
                                onClose={handleClose}
                                aria-labelledby="customized-dialog-title"
                                open={open}
                              >
                                <DialogTitle
                                  sx={{ m: 0, p: 2 }}
                                  id="customized-dialog-title"
                                ></DialogTitle>
                                <IconButton
                                  aria-label="close"
                                  onClick={handleClose}
                                  sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                                <DialogContent>
                                  <div className="welcome-back">
                                    <div className="ganesh-leaf">
                                      <img src={leaf} />
                                    </div>
                                    <div className="welcome-back-content">
                                      <img src={ganesh} />
                                      <h4>Booking is Successful</h4>
                                      <h5>
                                        We have successfully received your
                                        details
                                      </h5>
                                      <p>
                                        You will get a confirmation call or
                                        WhatsApp within 12 hrs of booking
                                      </p>
                                    </div>

                                    <div className="send-otp verify-otp">
                                      <Link to="/home">
                                        <Button
                                          disableRipple
                                          disableElevation
                                          variant="contained"
                                          className="app-btn app-btn-primary has-icon-end"
                                        >
                                          <span className="app-btn-text">
                                            Return to Homepage
                                          </span>
                                        </Button>
                                      </Link>
                                    </div>
                                  </div>
                                </DialogContent>
                              </BootstrapDialog>
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Container>
            )}
          </div>
        ))}
      </div>
      {loadingPayment && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // translucent background
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            overflow: "hidden",
            zIndex: 99999999999,
          }}
        >
          <div className="imageLoader">
            <img
              src={require("../images/loader.png")}
              alt="Logo"
              className="loaderLogo"
              style={{
                width: "100px",
                height: "100px",
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default NonLinearHorizontalStepper2;

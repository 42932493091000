import React, { useEffect, useRef, useState } from "react";
import durgamata from "../../images/discover-more/durgamatha.svg";
import sevaname from "../../images/champaign/image-01.png";
import sevaname1 from "../../images/champaign/image-02.png";
import sevaname2 from "../../images/champaign/image-03.png";
import bookingcart from "../../images/individual/add-to-cart.svg";
import BasicSelect from "./BasicSelect";
import { Link, useNavigate } from "react-router-dom";
import BasicAccordion from "../common/Accordion";
import {
  Grid,
  Button,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@mui/material";
import EventFaq from "./EventFaq";
import instance from "../../utils/Api";
import Typography from "@mui/material/Typography";
import { useAuth } from "../../utils/Auth";
import { useCart } from "../../pages/CartContext";
import Swipper3 from "./Swipper3";
import LoginDailog from "../LoginDailog";
import axios from "axios";
// import MultipleSelectPlaceholder from "./MultipleSelectPlaceholder";
import MultipleSelectPlaceholder2 from "../MultipleSelectPlaceholder2";
import api from "../../config/backend";
import ReactGA from "react-ga4";
import CloseIcon from "@mui/icons-material/Close";
import Process from "./Process";
import CustomizedDialogs from "../CustomizedDialogs";
import CustomizedDialogsEvent from "../CustomizedDialogsEvent";
// import MultipleSelectPlaceholder from "../MultipleSelectPlaceholder2";

const CustomTabPanel3 = ({
  sevas,
  setTriggerRefresh,
  isIndia,
  productDetails,
}) => {
  const token = localStorage.getItem("token");
  console.log(sevas, "sevas in custom tab panel 3");
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [newOpen, setNewOpen] = useState(false);

  const user = useAuth();
  useEffect(() => {
    setIsLoggedIn(user.isLogin);
  }, []);

  const { cartTotal, updateCartTotal } = useCart();
  const [visibleSection, setVisibleSection] = useState("section1");
  // const [triggerRefresh, setTriggerRefresh] = React.useState(0)
  const updatesevasdata = sevas.event_updates;
  // console.log(updatesevasdata)
  const sevasdata = sevas;
  console.log(sevasdata, "sevasdata--");
  const eventFaq = sevas.event_faqs;
  // console.log(eventFaq)

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [openModel, setOpenModel] = useState(null);
  const [selectedPriceList, setSelectedPriceList] = useState([]);

  console.log(selectedPriceList);

  const navigate = useNavigate();
  const [readMore, setReadMore] = useState({});

  console.log("readmore:", readMore);

  const handleReadOpen = (sevasId) => {
    console.log(sevasId, "sevaid");
    if (sevasId) {
      setReadMore((prevState) => ({
        ...prevState,
        [sevasId]: true,
      }));
    }
  };

  const handleReadClose = (sevasId) => {
    setReadMore((prevState) => ({
      ...prevState,
      [sevasId]: false,
    }));
  };

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    console.log({ sevasdata });
    sevasdata &&
      setSelectedPriceList(
        sevasdata.map((seva) => {
          const def = seva.seva_prices.find((option) => option.is_default);
          return {
            seva_id: seva.id,
            selected_price: def ? def : seva.seva_prices[0],
          };
        })
      );
  }, [sevasdata]);
  useEffect(() => {
    const handleScroll = () => {
      const sectionRefs = [
        { ref: section1Ref, name: "section1" },
        { ref: section2Ref, name: "section2" },
        { ref: section3Ref, name: "section3" },
        { ref: section4Ref, name: "section4" },
      ];
      // const currentScrollPosition = window.scrollY ;
      const currentScrollPosition = window.scrollY;
      for (let i = 0; i < sectionRefs.length; i++) {
        const section = sectionRefs[i];
        if (section.ref.current) {
          const sectionTop = section.ref.current.offsetTop - 210;
          // const sectionTop = section.ref.current.offsetTop;
          const sectionBottom = sectionTop + section.ref.current.clientHeight;

          if (
            currentScrollPosition >= sectionTop &&
            currentScrollPosition <= sectionBottom
          ) {
            setVisibleSection(section.name);
          }
        }
      }
    };

    const handleResize = () => {
      // Your code for handling resize, similar to your previous implementation
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    // Initial height measurement
    handleResize();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const handleNavClick = (sectionRef) => {
  //   sectionRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  //   setVisibleSection(sectionRef.current.dataset.section);
  // };

  const handleNavClick = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop - 190,
      behavior: "smooth",
    });
    setVisibleSection(ref.current.dataset.section);
  };
  const user_id = localStorage.getItem("user_id");
  const handleAddToCart = async (seva) => {
    openDialog();
    if (token) {
      const cartData = {
        cart: {
          user_id: user_id,
          seva_id: seva.id,
          seva_price_id: seva.seva_prices[0].id,
          is_prasadam_available: 0,
          qty: 1,
        },
      };
      // console.log('ccccc', cartData)

      try {
        const response = await axios.post(
          `${api}/api/cart`,
          { cart: cartData },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("response data for post in custom tab:", response.data);
        if (response.data.success === 1) {
          setTriggerRefresh((prev) => prev + 1);
          // if (response.data.data.length) {
          //     updateCartTotal(cartTotal + 1)
          // }
        } else {
          console.log("errorrrr");
        }
      } catch (error) {
        console.error("Error adding items to the cart:", error);
      }
    } else {
      openDialog();
    }
  };

  const [sevaForDialog, setSevaForDialog] = useState(null);

  const handleBookSeva = (seva, redirect) => {
    const sevapriceid = selectedPriceList.find(
      (item) => item.seva_id === seva?.id
    ).selected_price.id;
    if (token) {
      console.log(seva, "kjhgweckjwgckjwec");
      ReactGA.event({
        category: "Service Booked",
        action: "Book Seva",
        label: seva.title,
      });
      if (!seva) {
        console.error("Seva object is null or undefined.");
        return;
      }
      console.log(seva.id);
      console.log(redirect);

      localStorage.removeItem("newSelectedPriceId");

      if (!sevapriceid) {
        console.error("Selected price is null or undefined.");
        return;
      }

      const selectedPrice = seva?.seva_prices[0]?.base_price;

      if (redirect) {
        navigate(
          // `/checkout/${seva.slug}/${seva?.seva_prices[0]?.base_price}?events=true`
          `/checkout/${seva.slug ? seva.slug : seva.id}/seva/${sevapriceid}?events=true`
        );
        return;
      }

      // if (token) {
      navigate(
        // `/checkout/${seva.slug}/${seva?.seva_prices[0]?.base_price}?events=true`
        `/checkout/${seva.slug ? seva.slug : seva.id}/seva/${sevapriceid}?events=true`
      );

      //Todo: working on it
      // } else {
      //   console.log(seva);
      //   setSevaForDialog(seva);
      //   openDialog();
      // }
    } else {
      // setOpenModel(true)
      localStorage.setItem(
        "savedSevaSelection",
        `/checkout/${seva.slug ? seva.slug : seva.id}/seva/${sevapriceid}?events=true`
      );
      openDialog();
    }
  };

  const handleOpenModal = (seva) => {
    setOpenModel(seva.id);
    console.log("Open Modal", seva.id);
    // console.log(seva, "seva choose")
  };
  const handleClose = () => {
    console.log("close");
    setOpenModel(false);
  };

  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const handleButtonClick = (seva) => {
    window.fbq("track", "AddToCart");
    window.fbq("track", "InitiateCheckout");
    const sevaPrices = seva.seva_prices;

    console.log("handleButtonclick:", seva);

    if (isMobile) {
      if (!sevaPrices || sevaPrices.length <= 1) {
        handleBookSeva(seva);
      } else {
        handleOpenModal(seva);
      }
    } else {
      handleBookSeva(seva);
    }
  };

  // Render the handleredirect result wherever it makes sense in your component
  // For example, in the render method or in the JSX where you want to display it

  // const boxStyle = {
  //   maxHeight: "450px",
  //   overflowY: "scroll",
  //   borderTopWidth: "0px",
  // };

  const html = "<h1>Test</h1>";

  function handlePriceChange(selected_price, id) {
    console.log({ selected_price, id });
    setSelectedPriceList((previousValue) => {
      // return [{ seva_id: id, selected_price }]
      if (previousValue) {
        const copy = JSON.parse(JSON.stringify(previousValue));
        const index = copy.findIndex((item) => item.seva_id === id);
        console.log({ index });
        copy[index] = { seva_id: id, selected_price };
        console.log(copy);
        return copy;
      }
      return previousValue;
    });
  }

  return (
    <>
      <nav className="nav">
        <Button
          className={visibleSection === "section1" ? "active" : ""}
          onClick={() => handleNavClick(section1Ref)}
        >
          Seva
        </Button>
        <Button
          className={visibleSection === "section2" ? "active" : ""}
          onClick={() => handleNavClick(section2Ref)}
        >
          Description
        </Button>
        {/* <Button
          className={visibleSection === "section3" ? "active" : ""}
          onClick={() => handleNavClick(section3Ref)}
        >
          Update
        </Button> */}
        <Button
          className={visibleSection === "section4" ? "active" : ""}
          onClick={() => handleNavClick(section4Ref)}
        >
          Process
        </Button>
      </nav>

      <div ref={section1Ref} data-section="section1" className="section1">
        <div className="">
          <h3>Sevas</h3>
          <Grid container alignItems={"flex-start"} spacing={2}>
            {sevasdata &&
              sevasdata.map((seva, index) => {
                console.log({
                  t: selectedPriceList.find((item) => item.seva_id === seva.id)
                    ?.selected_price?.selling_price,
                });

                return (
                  <Grid
                    justify="flex-start"
                    key={index}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                  >
                    <div className="seva-box">
                      <img
                        src={`${seva.feature_image_id.domain}${seva.feature_image_id.url}`}
                        alt={seva.feature_image_id.name}
                      />
                      <h4 className="sevaTitle">{seva.title}</h4>
                      {seva.seva_prices && seva.seva_prices.length > 1 ? (
                        <div>
                          <MultipleSelectPlaceholder2
                            onPriceChange={handlePriceChange}
                            seva={seva}
                            openModel={openModel}
                            handleClose={handleClose}
                            setSevaForDialog={setSevaForDialog}
                            openDialog={openDialog}
                            isIndia={isIndia}
                            multiPrice={true}
                          />
                        </div>
                      ) : (
                        <div className="sevaPrice">
                          ₹{seva?.seva_prices[0]?.selling_price}
                        </div>
                      )}
                      <div
                        className="sevaDesc"
                        onClick={() => handleReadOpen(seva?.id)}
                      >
                        <div>Description</div>
                        <img
                          onClick={() => handleReadOpen(seva?.id)}
                          src={require("../../images/arrowDown.svg").default}
                          alt=""
                        />
                      </div>
                      <Dialog
                        open={readMore[seva?.id] || false}
                        onClose={() => handleReadClose(seva?.id)}
                        aria-labelledby="seva-description-title"
                        sx={{
                          "& .MuiDialog-paperScrollPaper": {
                            borderRadius: "8px!important",
                            boxShadow: "0px 4px 15px 0px #0000000D",
                            padding: "0 6px",
                          },
                          "& .MuiDialogContent-root": {
                            "&::-webkit-scrollbar": {
                              width: "6px",
                            },
                            "&::-webkit-scrollbar-track": {
                              backgroundColor: "#EAEAEA",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "#ff5b00",
                              borderRadius: "8px",
                              transform: "translateX(2px)",
                              height: "4px!important",
                            },
                          },
                        }}
                      >
                        <DialogTitle id="seva-description-title">
                          Description
                          <IconButton
                            aria-label="close"
                            onClick={() => handleReadClose(seva?.id)}
                            sx={{
                              position: "absolute",
                              right: 8,
                              top: 8,
                              color: "#ff5b00",
                              fontWeight: 700,
                              cursor: "pointer",
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent className="custom-camp-dialog-modal">
                          <DialogContentText
                            dangerouslySetInnerHTML={{
                              __html: seva?.description,
                            }}
                          ></DialogContentText>
                        </DialogContent>
                      </Dialog>

                      <div className="btnWrapper">
                        {/* <Button
                          onClick={() => handleAddToCart(seva)}
                          disableRipple
                          disableElevation
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "10px",
                            border: "1px solid #ff5b00",
                            padding: "10px 20px",
                            color: "#ff5b00",
                            fontFamily: "Objectivity",
                            fontSize: "15px",
                            fontWeight: 500,
                            textTransform: "unset",
                            backgroundColor: "white",
                            whiteSpace: "nowrap",
                            flex: "1 1 auto",
                            "&:hover": {
                              backgroundColor: "#ffe6cc",
                            },
                            fontSize: {
                              xs: "13px",
                              sm: "14px",
                            },
                          }}
                        >
                          <span
                            style={{
                              display: "inline-block",
                              marginRight: "10px",
                              width: "20px",
                              height: "20px",
                            }}
                          >
                            <img
                              src={bookingcart}
                              alt="cart"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </span>
                          Book Seva
                        </Button> */}

                        <Button
                          onClick={() => handleBookSeva(seva)}
                          variant="contained"
                          disableRipple
                          disableElevation
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "10px",
                            padding: "10px 20px",
                            color: "white",
                            fontFamily: "Objectivity",
                            fontSize: "15px",
                            fontWeight: 500,
                            textTransform: "unset",
                            backgroundColor: "#ff5b00",
                            whiteSpace: "nowrap",
                            flex: "1 1 auto",
                            fontSize: {
                              xs: "13px",
                              sm: "14px",
                            },
                            "&:hover": {
                              backgroundColor: "#ff7b33",
                            },
                          }}
                        >
                          <span>Book Seva</span>

                          <div style={{ width: "20px", height: "20px" }}>
                            <img
                              className="app-btn-img"
                              src="/images/icons/arrow-right.svg"
                              alt="arrow"
                              style={{
                                width: "100%",
                                height: "100%!important",
                              }}
                            />
                          </div>
                        </Button>
                      </div>
                    </div>
                  </Grid>
                );
              })}
          </Grid>
          <CustomizedDialogsEvent
            setTriggerRefresh={setTriggerRefresh}
            open={isDialogOpen}
            setOpen={setIsDialogOpen}
            handleClose={closeDialog}
            seva={sevaForDialog}
            handleBookSeva={handleBookSeva}
            setIsLogin={setIsLoggedIn}
          />
        </div>
      </div>

      <div ref={section2Ref} data-section="section2" className="section2">
        <div className="">
          <div className="description-content">
            <h3>Description</h3>
            <p
              dangerouslySetInnerHTML={{ __html: productDetails?.description }}
            ></p>
          </div>
        </div>
      </div>
      {/* <div ref={section3Ref} data-section="section3" className="section3">
        <div className="description-content">
          <h3>Updates</h3>
          {sevas.additional_information && sevas.additional_information.length === 0 ? (
            <div className="accordion">
              <Typography variant="h4" className="main-content">
                There are no updates for this events
              </Typography>
            </div>
          ) : (
            <p
              dangerouslySetInnerHTML={{ __html: sevas.additional_information }}
            ></p>
          )}
        </div>
      </div> */}
      <div ref={section4Ref} data-section="section4" className="section4">
        <div className="">
          <div className="faq-2">
            <div className="faq-main-content-2">
              {/* <EventFaq /> */}
              <h3>Puja Process</h3>
              {/* <BasicAccordion /> */}
              <Process />
            </div>
          </div>
        </div>
      </div>

      {/* <CustomizedDialogs
        isLogin={isLogin}
        setIsLogin={setIsLogin}
        setTriggerRefresh={setTriggerRefresh}
      /> */}
    </>
  );
};

export default CustomTabPanel3;
